import React, { Component } from "react";
import ReactPlayer from 'react-player'

class VideoThumb extends Component {
  constructor() {
    super();
  }

  render() {
    const isVimeo = this.props.vimeo ? 'vimeo' : ' ';

    return (
      <ReactPlayer
        url={this.props.url}
        className='react-player'
        controls={false}
        height={this.props.height}
        width={this.props.width}
        config={{ vimeo: { playerOptions: { background: true }}}}
        volume={0}
        muted={true}
        playing
        loop
        playsinline />
    );
  }
}

export default VideoThumb;
