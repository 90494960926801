import React, { Component } from "react";
import VideoThumb from "../components/video-thumb";
import Img from "gatsby-image";
import { Link } from "gatsby";

class ThumbnailGrid extends Component {
  constructor () {
    super()

    this.state = {
      update: false,
    }
  }
  componentDidMount () {
    if (this.props.windowWidth > 767 && this.props.windowWidth < 1024) {
      this.setSizing();
    }
  }
  shouldComponentUpdate (nextProps, nextState) {
    if (this.props.windowWidth === nextProps.windowWidth) {
      return true
    } else {
      this.setSizing();
      return false;
    }
  }

  setSizing () {
    if (this.state.update !== true) {
      this.setState({
        update: true,
      });
    }
  }

  render () {
    const thumbType = this.props.project ? 'project' : 'news';
    const { windowWidth } = this.props;
    const calculateTotalWidth = (thumb) => {
      let totalAspectRatio = thumb.thumbnail
        ? thumb.thumbnail.fluid.aspectRatio
        : 0;
      if (thumb.additionalThumbnails && thumb.additionalThumbnails.length > 0) {
        thumb.additionalThumbnails.forEach((additionalThumb) => {
          totalAspectRatio += additionalThumb.fluid.aspectRatio;
        });
      }
      return totalAspectRatio;
    };

    return (
      <div>
        {this.props.thumbnailObject.map((row, i) => {          
          if (row.length > 0) {
            return (
              <div key={i} className="row">
                {row.map((thumb, i) => {
                  if (thumb.thumbnail || thumb.vimeoThumbnail) {
                    let totalAspectRatio = calculateTotalWidth(thumb);
                    let widthCalc = "";
                    if (thumb.thumbnail) {
                      widthCalc = 402 * totalAspectRatio;
                    }

                    let vimWidthCalc = Math.floor(402 * 1.77727);
                    let width = `${widthCalc}px`;
                    let height = `402px`;
                    let vimWidth = `100%`;
                    let vimHeight = height;

                    if (windowWidth <= 768) {
                      width = `85vw`;
                      height = `auto`;
                      vimWidth = `85vw`;
                      vimHeight = `48vw`;
                    }

                    if (windowWidth > 768 && windowWidth <= 1024) {
                      height = `186px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 186 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(186 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (windowWidth > 1024 && windowWidth <= 1200) {
                      height = `219px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 219 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(219 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (windowWidth > 1200 && windowWidth <= 1400) {
                      height = `253px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 253 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(253 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (windowWidth > 1400 && windowWidth <= 1600) {
                      height = `288px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 288 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(288 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (windowWidth > 1600 && windowWidth <= 2000) {
                      height = `322px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 322 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(322 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (windowWidth > 2000 && windowWidth <= 2500) {
                      height = `345px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 345 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(345 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (windowWidth > 2500) {
                      height = `380px`;
                      vimHeight = height;
                      if (thumb.thumbnail) {
                        widthCalc = 380 * totalAspectRatio;
                      }
                      width = `${widthCalc}px`;
                      vimWidthCalc = Math.floor(380 * 1.77727);
                      vimWidth = `${vimWidthCalc}px`;
                    }

                    if (
                      thumb.thumbnail &&
                      thumb.thumbnail.file.contentType === "video/mp4"
                    ) {
                      width = `auto`;
                    }

                    if (
                      thumb.thumbnail &&
                      thumb.thumbnail.file.contentType === "video/mp4" &&
                      windowWidth <= 768
                    ) {
                      width = `85vw`;
                    }

                    return (
                      <div key={i} className="thumb-container">
                        <div
                          className={thumb.imageOnly ? "thumb-image-only" : "thumb"}
                          style={{
                            height: thumb.vimeoThumbnail ? vimHeight : height,
                            width:
                              thumb.vimeoThumbnail ||
                              (thumb.thumbnail.file.contentType ===
                                "video/mp4" &&
                                this.state.update)
                                ? vimWidth
                                : width,
                          }}
                        >
                          <Link
                            className="news-thumb"
                            to={`/${thumbType}/${thumb.slug}`}
                            state={{ prevPath: location.pathname }}
                          >
                            {thumb.vimeoThumbnail && (
                              <div className="vimeo">
                                <VideoThumb
                                  vimeo={true}
                                  url={`${thumb.vimeoThumbnail.vimeoVideoId}`}
                                  height={vimHeight}
                                  width={`${vimWidth}`}
                                />
                              </div>
                            )}
                            {!thumb.vimeoThumbnail &&
                              thumb.thumbnail &&
                              thumb.thumbnail.file.contentType ===
                                "video/mp4" && (
                                <VideoThumb
                                  vimeo={false}
                                  url={thumb.thumbnail.file.url}
                                  height={
                                    this.state.update ? vimHeight : "100%"
                                  }
                                  width={
                                    this.state.update ? `${vimWidth}` : "auto"
                                  }
                                />
                              )}
                            {!thumb.vimeoThumbnail &&
                              thumb.thumbnail &&
                              thumb.thumbnail.file.contentType !==
                                "video/mp4" && (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: '10px',
                                    height: height,
                                  }}
                                >
                                  {/* Siempre renderiza el thumbnail principal */}
                                  <Img
                                    fluid={thumb.thumbnail.fluid}
                                    //style={{ height: "100%",  width: thumb.additionalThumbnails ?  `${100 / (thumb.additionalThumbnails.length)}%` : '100%' }}
                                    style={{height: "100%", flex: 1}}
                                    
                                  />

                                {/* Render additionalThumbnails only if they exist */}
                                {thumb.additionalThumbnails &&
                                  thumb.additionalThumbnails.map(
                                    (additionalThumb, index) => (
                                      <Img
                                        key={`additional-${index}`} 
                                        fluid={additionalThumb.fluid}
                                        style={{
                                          height: "100%",
                                          flex: 1,
                                          //width: `${100 / (thumb.additionalThumbnails.length)}%`, // Ajusta el ancho basado en la cantidad de imágenes
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              )}
                            {/*  This one is for the homepage */}
                            {thumb.thumbnailText && (
                            <div
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html:
                                  thumb.thumbnailText.childMarkdownRemark
                                    .html,
                              }}
                            />
                          )}
                          {/* This one is for the artist page */}
                          {thumb.title && this.props.project && (
                            <p className="title">{!thumb.hideTitle && thumb.title}</p>
                          )}
                          </Link>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default ThumbnailGrid;
