import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import favicon from '../fonts/favicon.png'
import '../layouts/print-js.scss'
import '../layouts/reset.scss'
import '../layouts/common.scss'

export default ({ children, history }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet titleTemplate={`%s | ${data.site.siteMetadata.title}`} defaultTitle={data.site.siteMetadata.title}>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1, shrink-to-fit=no"/>
          <meta name="description" content="BRIDGE Artists is a New York based agency representing a select group of photographers, stylists, hair stylists, makeup artists, and manicurists." />
        </Helmet>
        <div>
          {children}
        </div>
      </>
    )}
  />
)
